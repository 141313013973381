import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import homeRoutes from "./Modules/homeRoutes"

const layoutChildren: Array<RouteRecordRaw> = []
let getLayoutFiles = require["context"]("./Modules", true, /\.ts$/)
getLayoutFiles.keys().forEach((key: string) => {
  layoutChildren.push(...getLayoutFiles(key).default)
})
const routes: Array<RouteRecordRaw> = [
  {
    path: '/:catchAll(.*)', // 会匹配任何路径，并将其放在$route.params.catchAll中
    redirect: '/404',
  },
  {
    path: '/404',
    component: () => import("@/views/Basic/NotFound/index.vue")
  },
  {
    path: "/layout",
    name: "/layout",
    component: () => import("@/views/Layout/index.vue"),
    redirect: "/Construct/DeliveryTeam/index",
    children: layoutChildren
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router


export interface ITopMenuListItem {
  name: string;
  path?: string
  children: Array<RouteRecordRaw>;
}
export const TopMenuList: ITopMenuListItem[] = [
  { name: "首页", path: "/", children: homeRoutes },
  { name: "卖车", children: [] },
  { name: "汽车资讯", children: [] },
  { name: "历史成交价", children: [] },
  { name: "关于我们", path: "/aboutUs", children: [] },

]