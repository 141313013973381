import { RouteRecordRaw } from "vue-router"
/**
 * 首页路由
 */
const routes: Array<RouteRecordRaw> = [
    {
        ...(() => { const path = "/aboutUs"; return { name: path, path } })(),
        component: () => import('@/views/AboutUs/index/index.vue'),
        meta: { title: "关于我们", isMenu: true }
    },
]
export default routes
