/*
 * @Description: 
 * @Autor: 万洲
 * @Date: 2022-10-24 15:37:28
 * @LastEditors: 万洲
 * @LastEditTime: 2022-11-17 16:35:47
 */
import { defineStore } from "pinia";
import { computed, ref, reactive, toRefs } from "vue";


const useConfigStore = defineStore("configStore", () => {
    const state = reactive({
        loadingFlag: false
    })
    const setLoadingFlag = (flag: boolean) => state.loadingFlag = flag
    return {
        ...toRefs(state), setLoadingFlag
    }
}, {
    // 开启状态持久化
    persist: false,
})
export default useConfigStore