import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'uno.css'
import "animate.css"
import "hover.css"
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/css/common.scss'
import '@/assets/css/init.scss'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createPinia } from 'pinia'
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(pinia)

app.use(router);
app.use(ElementPlus)
app.mount('#app')
