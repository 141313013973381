import { RouteRecordRaw } from "vue-router"
/**
 * 首页路由
 */
const routes: Array<RouteRecordRaw> = [
    {
        ...(() => { const path = "/"; return { name: path, path } })(),
        component: () => import('@/views/Home/index/index.vue'),
        meta: { title: "首页", isMenu: true }
    },

]
export default routes
